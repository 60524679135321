import { dev } from '$app/environment';
import { PUBLIC_TRACING_DSN, PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public';
import { log } from '$lib/appInsights';
import { clientInit } from '@jill64/sentry-sveltekit-cloudflare';

const onError = clientInit(PUBLIC_TRACING_DSN, {
	sentryOptions: {
		environment: dev ? 'dev' : (PUBLIC_SENTRY_ENVIRONMENT ?? 'production'),
	},
});

export async function handleError(input) {
	onError()(input);
	log.exception(input.error as Error | undefined);
}
